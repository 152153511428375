<template>
  <div class="tab-contents-wrap">
    <!-- Reservation List -->
    <div class="board-list-wrap" style="margin-top: 0">
      <template v-if="statusType === true">
      <div class="board-top-area">
        <div class="icon-notice">{{$t('content.event.EventReservation.txt124')}}</div>
        <!-- <div class="total-count">
          {{$t('content.event.EventReservation.txt125')}}
          <em class="space">{{vunues.length}}</em>{{$t('content.event.EventReservation.txt126')}}
        </div> -->
      </div>
      <ul class="board-list">
        <template v-for="(v, i) in vunues.slice(0, increase)">
          <li :key="i">
            <div class="list-item">
              <dl class="info">
                <dt>{{ v.reservedPlaceNames }}</dt>
                <dd>
                  <ul class="info-detail">
                    <li class="time">
                      <time>
                        {{ getDateString(v.startTime, v.endTime) }}
                      </time>
                    </li>
                    <li class="date">
                      <span>{{$t('content.event.EventReservation.txt127')}} :</span>
                      <time>{{$moment(v.insertDate.replace(/\./g,'-')).format('YYYY.MM.DD HH:mm')}}</time>
                    </li>
                    <li class="status">
                      <template>
                        <span>
                          {{$t('content.users.ServiceInfo.txt13')}} :
                          {{v.reservationStateName}}
                          ({{$moment(v.borrowChangeStatusDate.replace(/\./g,'-')).format('YYYY.MM.DD HH:mm')}})
                        </span>
                      </template>
                    </li>
                  </ul>
                </dd>
              </dl>
              <div class="right-area">
                <!-- <button class="btn-ghost btn-sm">신청 취소</button> -->
              </div>
            </div>
          </li>
        </template>
      </ul>
      <div class="news_more">
          <button class="btn_more" @click="addVunues" v-if="vunues.length>increase">
              <span class="ico_more">+</span>
              <span class="txt_more">more</span>
          </button>
      </div>
      <div class="btn_bottom_wrap">
        <button @click="goBack" class="btn-ghost">Back</button>
        <!-- <button button class="btn-more" @click="addVunues" v-if="vunues.length>increase">
          <span class="circle">
            <span class="icon-plus">+</span>
          </span>
          <em class="btn-text">{{$t('content.event.EventReservation.txt128')}}</em>
        </button> -->
      </div>
      </template>
      <template v-else>
        <div class="no-list-wrap" style="margin-top:4rem">
          <div class="no-list">
            <h6 data-aos="flip-left" class="aos-init aos-animate">{{$t('content.event.EventReservation.txt129')}}</h6>
          </div>
          <div class="btn_bottom_wrap">
            <button @click="goBack" class="btn-ghost">Back</button>
          </div>
        </div>
      </template>
    </div>
    <!-- Reservation List -->
  </div>
</template>
<script>
import History from '@/views/reservation/History.vue';

export default {
  name: 'MobileReservationHistory',
  extends: History,
};
</script>
<style scoped>
.board-list .list-item .info .info-detail .date{ padding-right: 6rem;}
.board-list-wrap{ margin-top: 4rem;}
</style>
